const initialState = {
    articleList: [],
    total: 1,
    params: {},
    allArticles: [],
    myArticles: [],
    totalArticles: [],
    articleData: {},
    isActive: false
}

const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ARTICLES':
            return {
                ...state,
                totalArticles: action.totalArticles,
                articleList: action.payload,
                total: action.totalPages,
                params: action.params
            }
        case 'GET_ALL_ARTICLES':
            return {
                ...state,
                allArticles: action.payload
            }
        case 'GET_MY_ARTICLES':
            return {
                ...state,
                myArticles: action.payload
            }
        case 'GET_ARTICLE':
            return {
                ...state,
                articleData: action.payload
            }
        case 'ARTICLE_FAIL':
            return {
                ...state,
                articleList: []
            }
        default:
            return { ...state }
    }
}
export default articleReducer
